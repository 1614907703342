<template>
    <a :href="url"
       :class="['biograb__link biograb__green', {'biograb__link_under' : isMail}]"
    >
      <slot></slot>
    </a>
</template>

<script>
export default {
  name: 'UiLink',
  props: {
    url: {
      type: String,
      default: '',
    },
    isMail: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
    .biograb__link {
        transition: ease .25s;
        font-family: 'Bai Jamjuree', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        position: relative;
        top: -3px;
        &:hover {
            color: #F2F2F2;
        }
        &_under {
            text-decoration: underline;
        }
    }
</style>
