const state = {
    kycData: null,
    isKycError: false,
    kycError: null,
    isKycLoaded: false,
    isKycPaid: false,
    isKycPaidAndAvailable: false,
    isNftPaidAndAvailable: false,
    isKycSuccessfullyDone: false,
};

const actions = {
    setKYCResult({ commit }, item) {
        commit('setKYCResult', item);
    },
    setKYCError({ commit }, item) {
        commit('setKYCError', item);
    },
    setIsKycPaidAndAvailable({ commit }, data) {
        commit('setIsKycPaidAndAvailable', data);
    },
    setIsNftPaidAndAvailable({ commit }, data) {
        commit('setIsNftPaidAndAvailable', data);
    },
};

const mutations = {
    setKYCResult(state, data) {
        state.kycData = data;
        state.isKycError = false;
        state.isKycLoaded = true;
    },
    setKYCError(state, data) {
        state.kycError = data;
        state.isKycError = true;
        state.isKycLoaded = true;
    },
    setIsKycPaidAndAvailable(state, data) {
        state.isKycPaidAndAvailable = data;
    },
    setIsNftPaidAndAvailable(state, data) {
        state.isNftPaidAndAvailable = data;
    },
};

const kyc = {
    namespaced: true,
    state,
    actions,
    mutations,
};

export default kyc;
