<template>
  <button
      class="biograb__ui-button"
      @focus="$emit('focus')"
      @focusout="$emit('focusout')"
      @click.prevent="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonUi',
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.biograb__ui {
  &-button {
    background: #00E300;
    color: #171717;
    cursor: pointer;
    box-shadow: 0px 2px 20px rgba(0, 227, 0, 0.28);
    border-radius: 40px;
    border: 0;
    &:disabled {
      opacity: .3;
      cursor: initial;
    }
  }
}
</style>
