<template>
  <div id="app" class="overflow-hidden">
    <router-view/>
    <get-kyc-request v-if="walletId"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import GetKycRequest from '@/components/KYC/GetKYCRequest.vue';
import parseJWT from '@/utils/parseJWT';
import UserRequest from '@/services/user';

export default {
  name: 'App',
  components: {
    GetKycRequest,
  },
  computed: {
    ...mapState({
      walletId: state => state.auth.walletId,
      isAuth: state => state.auth.isAuth,
      isAuthByEmail: state => state.auth.isAuthByEmail,
      authData: state => state.auth.authData,
      kycData: state => state.kyc.kycData,
    }),
  },
  methods: {
    ...mapActions({
      setUserAuthData: 'auth/setUserAuthData',
      setUserAuth: 'auth/setUserAuth',
      setUserAuthByEmail: 'auth/setUserAuthByEmail',
      setWalletId: 'auth/setWalletId',
    }),
    getWalletId(email) {
      UserRequest.getWalletByEmail(email).then((data) => {
        console.log(data);
        console.log(data.data);
        console.log(data.data.wallet);
        window.localStorage.setItem('abrakadabra-wallet', data.data.wallet);
        this.setWalletId(data.data.wallet);
      }).catch(e => {
        console.error(e);
      })
    },
  },
  mounted() {
    const abrakadabra = localStorage.getItem('abrakadabra');
    const abrakadabraName = localStorage.getItem('abrakadabra-name');
    const abrakadabraUid = localStorage.getItem('abrakadabra-uid');
    const abrakadabraEmail = localStorage.getItem('abrakadabra-email');
    const abrakadabraWallet = localStorage.getItem('abrakadabra-wallet');
    const tokenData = abrakadabra ? parseJWT(abrakadabra) : null;
    const abrakadabraExp = abrakadabra ? (tokenData.exp - tokenData.iat) * 1000 - 60000 : null;


    if (abrakadabra) {
      this.setUserAuthData({
        token: abrakadabra,
        expire: abrakadabraExp,
        userId: abrakadabraUid,
        name: abrakadabraName,
        email: abrakadabraEmail,
      });
      this.setWalletId(abrakadabraWallet);
      this.setUserAuth(true);
      this.setUserAuthByEmail(true);
    } else {
      localStorage.removeItem('abrakadabra');
      localStorage.removeItem('abrakadabra-name');
      localStorage.removeItem('abrakadabra-exp');
      localStorage.removeItem('abrakadabra-email');
      localStorage.removeItem('abrakadabra-wallet');
      this.setUserAuthData({});
      this.setUserAuth(false);
    }

    if (this.isAuth) {
      this.getWalletId(abrakadabraEmail);
    }
  },

}
</script>

<style lang="scss">
  body {
    background-color: #171717!important;
    font-family: 'Bai Jamjuree', sans-serif!important;
  }
  .text-left {
    text-align: left;
  }
  .biograb {
    &__main-wrap {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
  }
  .biograb__green {
      color: #00E300;
      &:hover {
        color: #F2F2F2;
      }
  }



  .biograb__bg-green {
      background-color: #00e3004f;
  }
  .biograb__orange {
      color: #FF8C2E;
  }

  .biograb__dark {
      color: #171717;
  }

  .biograb__white {
      color: #F2F2F2;
  }
  .biograb__error {
    color: #FF4949;
    position: relative;
  }
  .biograb__error-item {
    color: #FF4949;
    position: absolute;
    top: 0;
    left: 0;
  }

  .biograb__op_3 {
      opacity: .3;
  }
  .biograb__h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 80px;
  }

  .biograb__title {
    font-family: 'Bai Jamjuree';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 47.5px;
  }

  .biograb__h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
  }

  .biograb__h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  .biograb__text5 {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
  }

  .biograb__text6 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
  }

  .biograb__text7 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .biograb__text8 {
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 12px!important;
    line-height: 15px!important;
  }
  .biograb__text9 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }

  .biograb__pre-title {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
  }

  .biograb__bg-black {
    background: #171717;
  }

  .text-bold {
    font-weight: 700;
  }

  .img {
    max-width: 100%;
  }

  .z-index-9 {
    z-index: 9;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .biograb__border-gr {
    border: 1px solid #00e3004f;
  }

  .biograb__hover_green {
    &:hover {
      color: #00E300;
    }
  }

  a:hover {
    color: #F2F2F2;
  }

  .lh-0 {
    line-height: 0!important;
  }
</style>
