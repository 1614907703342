<template>
  <div>
    <ui-loader v-if="showLoader"/>
  </div>
</template>

<script>
import KycRequest from '@/services/kyc';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'getKYC',
  data() {
    return {
      showLoader: false,
    }
  },
  computed: {
    ...mapState({
      walletId: state => state.auth.walletId,
      kycData: state => state.kyc.kycData,
    })
  },
  methods: {
    async getKYCResult() {
      this.showLoader = true;
      await KycRequest.getKYCResultByWallet(this.walletId).then((data) => {
        this.setKYCResult(data?.data || data);
      }).catch((err) => {
        this.setKYCError(err);
      });
      this.showLoader = false;
    },
    ...mapActions({
      setKYCResult: 'kyc/setKYCResult',
      setKYCError: 'kyc/setKYCError',
    }),
  },
  mounted() {
    this.getKYCResult();
  },
}
</script>