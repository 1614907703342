import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const homePage = () => import('@/pages/Home.vue');
const previewPage = () => import('@/pages/Preview.vue');
const generatePage = () => import('@/pages/Generate.vue');
const GenerateBiograbId = () => import('@/pages/GenerateBiograbId.vue');
const uploadFilePage = () => import('@/pages/UploadFilePage.vue');
const nftListPage = () => import('@/pages/NftListPage.vue');
const kycPage = () => import('@/pages/KYC.vue');
const kycResultPage = () => import('@/pages/KYCResult.vue');

const faqPage = () => import('@/pages/FAQ.vue');

const authPage = () => import('@/pages/Auth.vue');

const notFoundPage = () => import('@/pages/NotFoundPage.vue');

const paidPage = () => import('@/pages/Paid.vue');
const paidSuccessPage = () => import('@/pages/PaidSuccess.vue');
const createProductPage = () => import('@/pages/CreateProduct.vue');

const routes = [
    {
        name: 'home',
        path: '/',
        component: homePage,
    },
    {
        name: 'preview',
        path: '/preview',
        component: previewPage,
    },
    {
        name: 'generate',
        path: '/generate',
        component: generatePage,
    },
    {
        name: 'generate-biograb-id',
        path: '/generate-biograb-id',
        component: GenerateBiograbId,
    },
    {
        name: 'upload-file',
        path: '/upload-nft',
        component: uploadFilePage,
    },
    {
        name: 'nft-lst',
        path: '/nft-list',
        component: nftListPage,
    },
    {
        name: 'kyc',
        path: '/kyc',
        component: kycPage,
    },
    {
        name: 'kyc-result',
        path: '/kyc-result',
        component: kycResultPage,
    },
    {
        name: 'faq',
        path: '/faq',
        component: faqPage,
    },
    {
        name: 'auth',
        path: '/auth',
        component: authPage,
    },
    {
        name: 'paid',
        path: '/paid',
        component: paidPage,
    },
    {
        name: 'create-product',
        path: '/create-product',
        component: createProductPage,
    },
    {
        name: 'paid-success',
        path: '/paid-success',
        component: paidSuccessPage,
    },
    {
        // will match everything
        path: '*',
        component: notFoundPage,
    }
];

export default new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});
