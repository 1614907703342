const state = {
    authData: {},
    isAuth: false,
    isAuthByEmail: false,
    walletId: null,
};

const actions = {
    setUserAuthData({ commit }, item) {
        commit('setUserAuthData', item);
    },
    setUserAuth({ commit }, isAuth) {
        commit('setUserAuth', isAuth);
    },
    setWalletId({ commit }, walletId) {
        commit('setWalletId', walletId);
    },
    setUserAuthByEmail({ commit }, value) {
        commit('setUserAuthByEmail', value);
    },
};

const getters = {
    bearer(state) {
        return state.authData.token ? `Bearer ${state.authData.token}`: null;
    },
    userName(state) {
        return state.authData.name || null;
    }
}

const mutations = {
    setUserAuthData(state, token) {
        state.authData = token
    },
    setUserAuth(state, isAuth) {
        state.isAuth = isAuth;
    },
    setWalletId(state, walletId) {
        state.walletId = walletId;
    },
    setUserAuthByEmail(state, value) {
        state.isAuthByEmail = value;
    },
};

const auth = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};

export default auth;
