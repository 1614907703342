import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/plugins/fa';
import { BootstrapVue } from 'bootstrap-vue';
import 'animate.css';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Buffer } from 'buffer/';
window.Buffer = window.Buffer || Buffer;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

Vue.component('UiModal', require('./components/UI/Modal.vue').default);
Vue.component('BiograbLogo', require('./components/UI/Biograb.vue').default);
Vue.component('UiLink', require('./components/UI/Link.vue').default);
Vue.component('ButtonUi', require('./components/UI/ButtonUi.vue').default);
Vue.component('UiSnakebar', require('./components/UI/Snakebar.vue').default);
Vue.component('UiLoader', require('@/components/UI/Loader.vue').default);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
