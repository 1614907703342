import axios from 'axios';
import config from './services-config.js';

export default {
    generateWalletByEmail(email) {
        console.debug("generateWalletByEmail...config", config);
        const protocol = config.user.protocol;
        const hostname = config.user.hostname;
        const port = config.user.port;
        const apiBase = config.user.apiBase;
        const url = `${protocol}://${hostname}:${port}${apiBase}/user/generate-wallet-by-email`;
        console.debug("generateWalletByEmail...url", url);
        return axios.post(url, { email }, {
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    getWalletByEmail(email) {
        console.debug("getWalletByEmail...config", config);
        const protocol = config.user.protocol;
        const hostname = config.user.hostname;
        const port = config.user.port;
        const apiBase = config.user.apiBase;
        const url = `${protocol}://${hostname}:${port}${apiBase}/user/wallet-by-email/${email}`;
        console.debug("getWalletByEmail...url", url);
        return axios.get(url);
    },
};
