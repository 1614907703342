import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import kyc from '@/store/modules/kyc';

Vue.use(Vuex);

const { hostname, port } = window.location;

const state = {
    hostname,
    port,
};

const getters = {};

const mutations = {};

const actions = {};

const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state,
    actions,
    mutations,
    getters,
    modules: {
        auth, kyc,
    },
});

export default store;
