import axios from 'axios';
import config from './services-config.js';

export default {
    getKYCResultByWallet(walletId) {
        console.debug("getKYCResultByWallet...config", config);
        const protocol = config.kyc.protocol;
        const hostname = config.kyc.hostname;
        const port = config.kyc.port;
        const apiBase = config.kyc.apiBase;
        const url = `${protocol}://${hostname}:${port}${apiBase}/kyc/status-by-wallet/${walletId}`;
        console.debug("getKYCResultByWallet...url", url);
        return axios.get(url);
    },
};
