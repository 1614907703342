<template>
  <transition
      name="fade"
      mode="out-in"
  >
    <div
        v-if="open"
        class="ui-modal-overlay"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        aria-modal="true"
        @click.self="hide('backdrop')"
    >
      <div
          class="ui-modal-wrapper"
          :class="isLg ? 'ui-modal-wrapper_lg' : ''"
      >
        <div class="ui-modal-content">
          <div
              v-if="!hideHeaderClose"
              @click="hide"
          >
            <slot name="modal-header">
              <div class="ui-modal-close cursor-pointer">

                X
              </div>
            </slot>
          </div>
          <div
              class="ui-modal-body"
              :class="[modalClass]"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import UiButtonIcon from './ButtonIcon.vue';

export default {
  name: 'UiModal',
  components: {  },
  props: {
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    hideHeaderClose: {
      type: Boolean,
      default: false,
    },
    isLg: {
      type: Boolean,
      default: false,
    },
    modalClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    show() {
      this.open = true;
      document.body.classList.add('ui-modal-open');
      this.$emit('show');
    },
    hide(from) {
      if (!this.noCloseOnBackdrop || (this.noCloseOnBackdrop && from !== 'backdrop')) {
        this.open = false;
        document.body.classList.remove('ui-modal-open');
        this.$emit('hidden');
      }
    },
  },
};
</script>


<style lang="scss">
.ui-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: rgba(225, 225, 225, .9);
  backdrop-filter: blur(10px);
  font-style: normal;
  &_transperent {
    background: rgba(225, 225, 225, .25);
  }
}

.ui-modal-wrapper {
  min-height: calc(100vh - 60px);
  max-width: 53.25rem;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  position: relative;
  pointer-events: none;
  margin: 1.75rem auto;
  padding: 60px 42px 0;
  &_lg {
    max-width: 75%;
    min-width: 75%;
    & .ui-modal-content {
      max-width: 100%;
    }
  }
  @media(max-width: 576px) {
    min-height: calc(100vh - 20px);
    max-width: 100%;
    padding: 75px 0 0;
    margin: 0 auto;
  }
}

.ui-modal-content {
  box-shadow: 0px 6px 36px rgba(0, 0, 0, 0.06);
  max-width: 660px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .625rem;
  outline: 0;

  @media(max-width: 576px) {
    max-width: 100%;
    border-radius: 0;
  }
}

.ui-modal-close {
  box-shadow: 0px 6px 36px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  position: absolute;
  margin-top: -60px;
  background-color: #fff;
  right: 0;
  z-index: 1;
}

.ui-modal-body {
  padding: 48px;

  @media(max-width: 576px) {
    padding: 48px 16px;
  }
}

.fl-sidebar-buttons-internal .ui-modal-body {
  padding: 48px !important;

  @media(max-width: 576px) {
    padding: 48px 16px;
  }
}

body.ui-modal-open {
  overflow: hidden;

  @media(max-width: 576px) {
    padding-right: 4px;
  }
}
.ui-modal-overlay.ui-modal-great {
  background: rgba(0,0,0,.62);
  backdrop-filter: none !important;
  &.ui-modal-great-redirect {
    backdrop-filter: blur(15px)!important;
    & .ui-modal-wrapper {
      padding-left: 235px!important;
    }
  }
  .ui-modal-wrapper {
    max-width: 85%;
    width: 85%;
    opacity: 1;
    visibility: visible;
    transition: all 0.2s ease-out;
    padding: 0 0 0 90px !important;
    margin: 0;
    float: right;
    & .ui-modal-content {
      max-width: 100%;
      height: 100vh;
      border-radius: 0;
      & .ui-modal-body {
        padding: 0;
        flex: 1 1 auto;
        height: 100%;
        & > div {
          height: 100%;
        }
      }
    }
  }
  .ui-modal-close {
    left: -70px;
    top: 6rem;
    width: 46px;
    height: 46px;
    line-height: 0;
    border-radius: 20px;
    position: absolute!important;
    margin: -60px 0 0 0!important;
    background-color: #fff;
    right: 0;
    opacity: 1;
    z-index: 1;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    &:hover {
      color: #FF2B3E;
    }
  }
  button {
    &:focus {
      outline: none;
    }
  }
  a {
    text-decoration: none;
  }
}

.ui-modal-overlay.ui-modal-drawer {
  > .ui-modal-wrapper {
    max-width: 100%;
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    & .ui-modal-content {
      max-width: 100%;
      & .ui-modal-body {
        padding: 0;
      }
    }
  }
}


@media(max-width: 992px) {
  .ui-modal-overlay.ui-modal-great {
    & .ui-modal-wrapper {
      max-width: 85%;
      width: 85%;
    }
  }
}

@media(max-width: 768px) {
  .ui-modal-overlay.ui-modal-great.ui-modal-great-redirect {
    & .ui-modal-wrapper {
      padding-left: 165px !important;
    }
  }
}
@media(max-width: 576px) {
  .ui-modal-overlay.ui-modal-great, .ui-modal-overlay.ui-modal-great.ui-modal-great-redirect {
    & .ui-modal-wrapper {
      max-width: 100%;
      width: 100%;
      padding-left: 0!important;
    }
  }
}
</style>
