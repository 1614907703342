<template>
  <div
      v-if="messages.length"
      class="ui-snakebar"
      :class="[position]"
  >
    <div
        v-for="(item ,idx) in messages"
        :key="idx"
        class="ui-snakebar__item biograb__text6"
        :class="{
                'ui-snakebar__item_with-btn': btnText,
            }"
        @click="pop(idx)"
    >
      <div v-html="item.message"></div>

      <button
          v-if="btnText"
          type="button"
          name="button"
          class="btn btn-primary ui-snakebar__btn biograb__text5 ml-24"
      >
        {{ btnText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSnakebar',
  props: {
    btnText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      messages: [],
      position: '_left',
      showTime: 4000,
    };
  },
  mounted() {
    document.addEventListener('biograb_errors_snakebar', (event) => {
      this.show(event.detail.errorDescription);
    });

    document.addEventListener('biograb_event_snakebar', (event) => {
      this.position = event.detail?.position;
      this.showTime = event.detail?.showTime || 4000
      this.show(event.detail.data);
    });
  },
  beforeDestroy() {
    window.removeEventListener('biograb_errors_snakebar', this.show);
    window.removeEventListener('biograb_event_snakebar', this.show);
  },
  methods: {
    show(message) {
      if (typeof message === 'object') {
        let index = 0;
        message.forEach((item) => {
          setTimeout(() => {
            this.pushMessage(item, index);
          }, (index) * 500);
          index++;
        });
      } else {
        this.pushMessage(message);
      }
    },
    pushMessage(message) {
      const msgObj = {
        message,
        timer: setTimeout(this.pop, this.showTime),
      };
      this.messages.push(msgObj);

      return true;
    },
    pop(i = 0) {
      if (this.messages[i]) {
        clearTimeout(this.messages[i].timer);
      }
      this.messages.splice(i, 1);
    },
  },
};
</script>

<style lang="scss">
.ui-snakebar {
  position: fixed;
  z-index: 10000;
  left: 0;
  bottom: 0;
  padding: 24px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  max-width: 350px;

  &._right {
    right: 0;
    left: auto;
    align-items: flex-end;
  }

  &__item {
    background: #F2F2F2;
    color: #000;
    border-radius: 5px;
    padding: 1rem;
    margin-top: 8px;

    &_with-btn {
      padding: 12px 8px 12px 16px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  &__btn {
    padding: 6px 16px;
  }
}
</style>
