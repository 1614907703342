<template>
  <span class="biograb__green">Bio<span class="biograb__white">grab</span></span>
</template>

<script>
export default {
  name: 'Biograb-title',
  data: () => ({
  }),
}
</script>