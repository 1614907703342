const config = {
    kyc: {
        protocol: 'https',
        hostname: 'biograb.tech',
        port: '443',
        apiBase: '/biograb-pinata/api/v2'
    },
    generateId: {
        protocol: 'https',
        hostname: 'biograb.tech',
        port: '443',
        apiBase: '/api/v1'
    },
    nfts: {
        protocol: 'https',
        hostname: 'biograb.tech',
        port: '443',
        apiBase: '/biograb-pinata/api/v2'
    },
    payments: {
        protocol: 'https',
        hostname: 'biograb.tech',
        port: '443',
        apiBase: '/stripe-payments/api/v1'
    },
    auth: {
        protocol: 'https',
        hostname: 'biograb.tech',
        port: '443',
        apiBase: '/api'
    },
    user: {
        protocol: 'https',
        hostname: 'biograb.tech',
        port: '443',
        apiBase: '/biograb-pinata/api/v2'
    },
}
export default config